@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Inter:wght@100;200;300;400;500;600;700;800;900&family=Public+Sans:wght@100;200;300;400;500;600;700;800;900&display=swap");

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

textarea::placeholder {
  font-weight: 300 !important;
  color: #a6abb7;
}

* {
  box-sizing: border-box;
  padding: 0px;
  margin: 0px;
  /* font-family: "Inter", sans-serif; */

  font-family: "Inter", sans-serif !important;
  /* color: #000000; */
}

button {
  font-weight: 500 !important;
}

a {
  text-decoration: none;
  color: inherit;
}

button {
  text-transform: none !important;
}

.container {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.container .content {
  width: 100%;
  max-width: 1250px;
  padding-inline: 16px;

  height: 100%;

  display: flex;
  flex-direction: column;

  align-items: flex-start;
  justify-content: flex-start;
}

.fullHeight {
  min-height: max(calc(100dvh - 110px), 700px);
  /* min-height: max(100dvh, 700px); */
}

.max1Lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 1; /* number of lines to show */
  line-clamp: 1;
  -webkit-box-orient: vertical;
}
.max2Lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* number of lines to show */
  line-clamp: 2;
  -webkit-box-orient: vertical;
}
.max3Lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* number of lines to show */
  line-clamp: 3;
  -webkit-box-orient: vertical;
}
.max4Lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4; /* number of lines to show */
  line-clamp: 4;
  -webkit-box-orient: vertical;
}
.max5Lines {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 5; /* number of lines to show */
  line-clamp: 5;
  -webkit-box-orient: vertical;
}

* img {
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -o-user-select: none;
  user-select: none;
  pointer-events: none;
}

.user-container {
  /* display: flex;
  justify-content: space-between; */
  transform: translateX(0%);
  transition: all 0.5s ease;
}

.user-container.left.slide-out {
  transform: translateX(-100vw);
}
.user-container.right.slide-out {
  transform: translateX(100vw);
}

@media only screen and (min-width: 940px) {
  .user-container {
    transition: all 1s ease;
  }
}

@media print {
  .no-print,
  .no-print * {
    display: none !important;
  }
}

.input-styling{
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* width: 100%; */
  font-family: 'Courier New', Courier, monospace !important;
  font-size: 1rem;
  text-shadow: 0px 0px 5px rgba(139, 139, 139, 0.962);
  font-weight: 700;
  padding: 1rem 0;
  margin: 1rem 8%;
  padding-top: 1.5rem;
  background-color: rgb(82, 166, 244);
  border: 2px solid;
  border-radius: 10px;
  color: rgb(255, 255, 255);}

.input-styling input{
  margin-bottom: 0.8rem;
  border: 1px solid rgb(8, 96, 101);
  border: none;
  padding: 0.4rem 1.5rem;
  margin-top: 0.3rem;
  border-radius: 6px;
  text-align: center;
  outline: none;
  width: 70%;
  max-width: 50vh;
}

.input-styling button {
  background: rgb(0, 45, 122);
  padding: 0.5rem 1.5rem;
  margin-top: 0.4rem;
  border-radius: 10px;
  border: none;
  color: rgb(255, 255, 255);
}

.input-styling button:hover{
  background: rgb(1, 66, 180);
  cursor: pointer;
}